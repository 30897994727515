<template>
  <div id="shop-membership-select">
    <b-card v-if="!success && !failure">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </b-card>

    <div v-if="success">

      <div v-html="page.html"></div>
        <template v-for="item in companies_shop_items">

          <div v-bind:key="item.company.company_id">

            <hr/>

            <b-row>
              <b-col>
                <ShopCompanyMembershipBox
                  v-if="item.shop_items.length > 0"
                  class="mt-8"
                  :pageData="local_page_data"
                  :company_shop_items="item"
                  :preselect="item.shop_items.length === 1 ? item.shop_items[0].shop_item_id : null"
                  @onShopItemSelected="onCompanyMembershipSelected"
                />
              </b-col>
            </b-row>
          </div>

        </template>

        <div v-if="extras.length > 0" class="mt-8">

          <template v-for="(shopitem, j) in extras">
            <ShopExtraItem
              v-bind:key="j"
              v-if="renderItem(shopitem.rules)"
              :shopitem="shopitem"
              ref="shopItemRow"
              @removeShopItem="removeShopItem"
              @addShopItem="addShopItem"
            />

          </template>
        </div>

        <b-row>
          <b-col>
            <b-form-group
              v-if="has_code_campaigns"
              :valid-feedback="$t('PAGES.SHOPITEM.CAMPAIGN_CODE_VALID')"
              :invalid-feedback="$t('PAGES.SHOPITEM.CAMPAIGN_CODE_INVALID')"
              :state="campaign_code_valid">
              <b-row class="d-flex justify-content-start flex-nowrap">
                <b-col class="col-auto pr-0">
                  <label for="input-code">{{$t('PAGES.SHOPITEM.CAMPAIGN_CODE')}}</label>
                </b-col >
                <b-col class="col-auto pl-0">
                  <tipicon :tip="$t('PAGES.SHOPITEM.CAMPAIGN_CODE_TIP')"></tipicon>
                </b-col>
              </b-row>
              <b-row align-v="center" class="flex-nowrap">
                <b-col sm="6" md="4" style="min-width: 150px;">
                  <b-form-input id="input-code" v-model.trim="campaign_code_input" v-on:keydown.enter.prevent="apply_campaign_code"/>
                </b-col>
                <b-col>
                  <b-button variant="primary" @click.prevent="apply_campaign_code">{{$t('PAGES.SHOPITEM.APPLY_CAMPAIGN_CODE')}}</b-button>
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
        </b-row>

        <div v-if="cart.length > 0" class="mt-8">
          <b-row>
            <b-col>

              <ShopCartTable
                :total_price="total_price"
                :total_vat="total_vat"
                :cart="cart"
                :discount="discount"
                @deleteItemClicked="deleteItemClicked"
              />

            </b-col>
          </b-row>
        </div>


    </div>
  </div>
</template>

<style lang="scss" scoped>


.price {
  color: #646c9a;
  font-weight: 600;
}

.hdr {
  font-weight: bold;
}
</style>

<script>
import ShopCompanyMembershipBox from '@/view/pages/members/register/registerwizard/ShopCompanyMembershipBox.vue';
import ShopExtraItem from '@/view/pages/members/register/registerwizard/ShopExtraItem.vue';
import ShopCartTable from '@/view/pages/members/register/registerwizard/ShopCartTable.vue';

import tipicon from '@/view/components/TipIcon.vue';
import axios from 'axios';

import { required } from 'vuelidate/lib/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import dayjs from 'dayjs';

export default {
  name: 'register-shop-page',
  mixins: [ toasts ],
  props: ['age', 'page', 'page_data', 'company_id', 'company_ids', 'membershipUpdated', 'member_id','token','is_renew'],
  emits: ['wizardPageWasUpdated', 'update:page_data', 'membershipUpdated'],
  components: {

    ShopExtraItem,
    ShopCartTable,
    ShopCompanyMembershipBox,
    tipicon
  },
  computed: {

  },
  data() {
    return {
      local_page_data: {},
      has_code_campaigns: false,
      loaded: false,
      form: {
        membership: null
      },
      extras: [],
      total_price: 0,
      total_vat: 0,
      cart: [],
      success: false,
      failure: false,
      companies_shop_items: [],
      memberships: {},
      membership_shop_items: [],
      memberships_in_cart: [],
      currency: 'SEK',
      campaigns: [],
      campaign_code: null,
      campaign_code_input: '',
      campaign_code_valid: null,
      campaign_loader: false,
      discount: null,
    };
  },
  validations: {
    form: {
      membership: {
        required
      }
    }
  },
  watch: {
    page_data: {
      deep: true,
      handler(val) {
        this.local_page_data = { ...val };
      }
    },
    company_ids: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.load_items();
      }
    },

  },
  mounted() {
    if (this.page_data) {
      this.local_page_data = { ...this.page_data };
    }
    this.formWasUpdated();
    this.load_campaigns();
    this.load_items();
  },
  methods: {

    async load_campaigns() {
      this.campaigns = [];
      try {
        const res = await axios.get(`/campaign/public/${this.company_id}`);
        if (res.status === 200) {
          this.campaigns = res.data.public_campaigns;
          this.has_code_campaigns = res.data.has_active_campaigns;
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
        }
      }
      catch(err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_GET_DATA'));
      }
    },

    campaign_date_valid(data) {
      if (data) {
        const campaign = this.campaigns.find(item => item.id === data.campaign.id);
        if (campaign) {
          const now = dayjs();
          const from = dayjs(campaign.from_date);
          if (now < from) { return false; }
          const to = dayjs(campaign.to_date);
          if (now > to) { return false; }
          return true;
        }
      }
      return false;
    },

    apply_discounts(data) {

     var total_discount = 0;

     if (data) {
       for (var i = 0; i < this.cart.length; ++i) {
         const campaign_item = data.shop_items.find(item => item.shop_item_id === this.cart[i].shop_item_id);
         if (campaign_item) { total_discount += campaign_item.discount; }
       }
     }

     if (total_discount > 0) {
      total_discount = +(Math.round(total_discount + "e+2")  + "e-2"); // 2 decimals round
      this.discount = {campaign_name: data.campaign.name, campaign_descr: data.campaign.descr, total_discount };
     }
     else {
      this.discount = null;
     }

     this.calcTotals();
   },

   update_discounts(apply_input_code) {

     if (!apply_input_code && !this.campaign_code) { return; }

     this.campaign_loader = true;
     const items = this.cart.map(item => { return { shop_item_id: item.shop_item_id, quantity: 1 } });
     const check_code = apply_input_code ? this.campaign_code_input : this.campaign_code;

     axios
         .post(`/campaign/check_code`, { code: check_code, shop_items: items })
         .then(res => {
           this.campaign_loader = false;
           if (res.status === 200 && this.campaign_date_valid(res.data)) {
             this.campaign_code_valid = true;
             this.campaign_code = res.data.code;
             this.form.campaign_code = this.campaign_code;
             this.apply_discounts(res.data);
             if (apply_input_code) { this.formWasUpdated(); }
           }
           else { // no particular hadling of status 404 right now
             this.campaign_code_valid = false;
             this.campaign_code = null;
             this.form.campaign_code = null;
             this.apply_discounts(null);
           }
         })
         .catch(err => {
           this.campaign_loader = false;
           console.error(err);
           this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.PAGES.SHOPITEM.UNABLE_APPLY_CAMPAIGN_CODE'));
         });
   },

   apply_campaign_code() {

     this.campaign_code = null;
     this.campaign_code_valid = false;

     if (this.campaign_code_input.length < 4) {
       this.apply_discounts(null);
     }
     else {
       this.update_discounts(true);
     }
   },

    renderItem(rules) {

      if (!this.pageData.data) return true;

      if (rules === null || rules === undefined) { return true; }

      return true;

/*      return rules => {
        if (rules !== null && rules !== undefined)
          return (
            (parseInt(rules.age_min, 10) || 0) <= this.pageData.data.age &&
            this.pageData.data.age < (parseInt(rules.age_max, 10) || 999)
          );
        else return true;*/

    },

    formWasUpdated() {

      this.$emit('wizardPageWasUpdated', this.page.type, { ...this.form, cart: this.cart, selected_membership: this.memberships_in_cart }, this.isNextButtonOK());
      //this.$emit('wizardPageWasUpdated', this.page.type, this.form, this.isNextButtonOK());
    },
    isNextButtonOK() {
      let numselections = 0;

      for (const c of this.companies_shop_items) {
        if (c.shop_items.length > 0) {
          numselections++;
        }
      }

      return this.memberships_in_cart.length === numselections;
    },
    calculateNextButton() {
      this.calcTotals();

      let numselections = 0;

      for (const c of this.companies_shop_items) {
        if (c.shop_items.length > 0) {
          numselections++;
        }
      }

      this.local_page_data[this.page.id] = {
        ...this.local_page_data[this.page.id],
        form: { ...this.form, cart: this.cart },
        validation: {
          $invalid: this.memberships_in_cart.length !== numselections
        }
      };
      this.$emit('update:page_data', this.local_page_data);
    },

    getData() {
      return {
        shop_items: this.cart,
        cart: this.cart,
        total_price: this.total_price,
        total_vat: this.total_vat,
        campaign_code: this.campaign_code
      };
    },

    onCompanyMembershipSelected(company_id, shop_item_id) {

      if (!company_id || !shop_item_id) {
        return;
      }

      const int_shop_item_id = parseInt(shop_item_id+'');

      const company_memberships = this.membership_shop_items.filter(msi => msi.company_id === company_id);

      for (const cm of company_memberships) {
        cm.quantity = 0;

        // remove any potentially previously selected shop items for the company
        this.cart = this.cart.filter(ci => ci.shop_item_id !== cm.shop_item_id);
        this.memberships_in_cart = this.memberships_in_cart.filter(ci => ci.shop_item_id !== cm.shop_item_id);
      }

      const selected_membership = this.membership_shop_items.find(msi => msi.shop_item_id === int_shop_item_id);
      selected_membership.quantity = 1;
      selected_membership.is_membership = true;

      this.memberships_in_cart.push(selected_membership);
      this.cart.push(selected_membership);

      this.update_discounts();

      this.calculateNextButton();

      this.$emit('membershipUpdated', this.memberships_in_cart);

      this.formWasUpdated();
    },

    calcTotals() {
      this.total_price = 0;
      this.total_vat = 0;

      for (var i = 0; i < this.cart.length; ++i) {
        this.total_price += parseInt(this.cart[i].price_sek, 10);
        this.total_vat += parseInt(this.cart[i].vat_sek, 10);
      }

      if (this.discount) {
        this.total_price -= this.discount.total_discount;
      }

      if (this.total_price > 0) {
        this.form.mustpay = true;
      } else {
        this.form.mustpay = false;
      }

      this.total_price = +(Math.round(this.total_price + "e+2")  + "e-2"); // 2 decimals round

      this.form.total_price = this.total_price;
      this.form.total_vat = this.total_vat;
    },

    removeShopItem(shop_item_id) {
      for (var i = 0; i < this.cart.length; ++i) {
        if (this.cart[i].shop_item_id === shop_item_id) {
          this.cart[i] = { ...this.cart[i], quantity: this.cart[i].quantity - 1 };
          // Vue3 compatability for Vue.set
          this.cart = [...this.cart];

          if (this.cart[i].quantity === 0) {
            this.cart.splice(i, 1);
            break;
          } else {
            this.cart[i] = { ...this.cart[i], price_sek: this.cart[i].quantity * this.cart[i].p_pi_sek };
            this.cart[i] = { ...this.cart[i], vat_sek: this.cart[i].quantity * this.cart[i].v_pi_sek };
            // Vue3 compatability for Vue.set
            this.cart = [...this.cart];
          }
        }
      }
    },

    getItem(shop_item_id) {
      const membership = this.membership_shop_items.find(item => item.shop_item_id === shop_item_id);

      if (membership) {
        return membership;
      }

      const extra = this.extras.find(item => item.shop_item_id === shop_item_id);

      return extra;
    },

    getPriceForItem(shop_item_id) {
      return parseInt(this.getItem(shop_item_id).price_sek, 10);
    },

    getVatForItem(shop_item_id) {
      return parseInt(this.getItem(shop_item_id).vat_sek, 10);
    },

    addShopItem(shop_item_id) {
      var found = false;
      for (var i = 0; i < this.cart.length; ++i) {
        if (this.cart[i].shop_item_id === shop_item_id) {
          this.cart[i] = { ...this.cart[i], quantity: this.cart[i].quantity + 1 };
          this.cart[i] = { ...this.cart[i], price_sek: this.cart[i].quantity * this.cart[i].p_pi_sek };
          this.cart[i] = { ...this.cart[i], vat_sek: this.cart[i].quantity * this.cart[i].v_pi_sek };
          // Vue3 compatability for Vue.set
          this.cart = [...this.cart];
          found = true;
          break;
        }
      }

      if (!found) {
        var item = this.getItem(shop_item_id);

        this.cart.push({
          name: item.name,
          shop_item_id: shop_item_id,
          quantity: 1,
          price_sek: item.price_sek,
          vat_sek: item.vat_sek,
          p_pi_sek: item.price_sek,
          v_pi_sek: item.vat_sek,
          is_membership: false
        });
      }

      this.formWasUpdated();
    },

    deleteItemClicked(shop_item_id) {
      for (var i = 0; i < this.cart.length; ++i) {
        if (this.cart[i].shop_item_id === shop_item_id) {
          this.cart.splice(i, 1);
          break;
        }
      }

      for (var i = 0; i < this.extras.length; ++i) {
        if (this.extras[i].shop_item_id === shop_item_id) {
          this.$refs.shopItemRow[i].clear();
          break;
        }
      }

      this.formWasUpdated();
    },

    loadExtras() {
      axios
        .get(`/shop_item/extras`)
        .then(res => {
          this.extras = res.data;
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte lista merförsäljning');
        });
    },

    async load_items_manually(company_ids) {

      try {
        this.success = false;
        this.failure = false;

        this.cart = [];
        this.memberships_in_cart = [];
        this.membership_shop_items = [];

        const res = await axios.post(`/company/list_shop`, { company_ids: company_ids });

        if (res.status === 200) {
          this.companies_shop_items = res.data;

          for (const cmem of this.companies_shop_items) {
            for (const si of cmem.shop_items) {
              this.currency = si.currency;
              this.membership_shop_items.push(si);
            }
          }

          this.calculateNextButton();
          this.formWasUpdated();
          this.success = true;
        }
      }
      catch (err) {
        console.error('load_items_manually error', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_GET'));
        this.failure = true;
      }
    },

    async load_items() {
      try {

        this.loadExtras();
        this.success = false;
        this.failure = false;

        this.loaded = true;

        this.cart = [];
        this.memberships_in_cart = [];
        this.membership_shop_items = [];

        const company_ids = this.company_ids;

        if (company_ids.length === 0) {
          company_ids.push(this.company_id);
        }

        const res = await axios.post(`/company/list_shop`, { company_ids: company_ids });

        if (res.status === 200) {
          this.companies_shop_items = res.data;

          for (const cmem of this.companies_shop_items) {
            for (const si of cmem.shop_items) {
              this.currency = si.currency;
              this.membership_shop_items.push(si);
            }
          }

          this.calculateNextButton();
          this.formWasUpdated();
          this.success = true;
        }
      }
      catch (err) {
        console.error('load_items errors', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBERSHIP.UNABLE_GET'));
        this.failure = true;
      }

    }
  }
};
</script>
