<template>
  <div class="shop-company-membership-box">
    <div class="mb-0" style="clear: both;">
      <form>
        
        <div class="pb-2">
          <h4 class="mt-4 mb-4">{{company_shop_items.company.name}}</h4>
          <template v-for="(shopitem, k) in company_shop_items.shop_items">
            <PrettyOption
              v-if="isWithinAge(shopitem.rules)"
              class="mb-8"
              v-bind:key="k"
              :value="shopitem.shop_item_id"
              name="shopitem"
              v-model="selectedShopItemId"
              :img_url="shopitem.img_url"
              :title="shopitem.name"
              :descr="shopitem.descr"
              :price="shopitem.price_sek"
              :currency="shopitem.currency"
              @change="onSelectShopItem"
            />
          </template>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import PrettyOption from '@/view/pages/members/register/registerwizard/PrettyOption.vue';
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'shop-company-membership-box',
  mixins: [ toasts ],
  props: ['company_shop_items','pageData','preselect'],
  emits: ['onShopItemSelected'],
  components: {
    PrettyOption,
  },
  computed: {
    renderItem() {
      if (!this.pageData.data) return () => false;

      return rules => {
        if (rules !== null && rules !== undefined)
          return (
            (parseInt(rules.age_min, 10) || 0) <= this.pageData.data.age &&
            this.pageData.data.age < (parseInt(rules.age_max, 10) || 999)
          );
        else return true;
      };
    }
  },
  watch: {
    preselect(newValue, oldValue) {
      if (newValue) {
        this.selectedShopItemId = newValue;
        this.onSelectShopItem();
      }
    },
    selectedShopItemId(newValue, oldValue) {
      if (newValue) {
        this.onSelectShopItem();
      }
    }
  },
  data() {
    return {
      selectedShopItemId: this.preselect
    };
  },
  mounted() {
    this.$emit('onShopItemSelected', this.company_shop_items.company.company_id, this.selectedShopItemId);
  },
  methods: {
    getAge(date) {
      return dayjs().diff(
        date,
        'years'
      );

      return 18;
    },
    determineAge(pagedata) {
      if (pagedata.personnr && pagedata.personnr.personnr) {
        return this.getAge(pagedata.personnr.personnr.substring(0,8));
      }
      
      if (pagedata.details && pagedata.details.dob) {
        return this.getAge(pagedata.details.dob);
      }

      return null;
    },
    isWithinAge(rules) {

      if (!rules.has_age_rule) {
        return true;
      }

      if (this.pageData.data) {
        const age = this.determineAge(this.pageData.data);
        if (age === null) {
          return true;
        }

        if (age > rules.max_age) {
          return false;
        }

        if (age < rules.min_age) {
          return false;
        }

        return true;
      }

      return true;
    },
    onSelectShopItem() {
      this.$emit('onShopItemSelected', this.company_shop_items.company.company_id, this.selectedShopItemId);
    }
  }
};
</script>
